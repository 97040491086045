import Dialog from "@mui/material/Dialog";
import { grey } from "@mui/material/colors";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IntlEnum, Button } from "@common/components";
import { IconButton } from "@common/components";
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { forwardRef, useEffect, useRef } from "react";
import { TableInstance } from "react-table";
interface ColumnsDialogProps {
  open: boolean;
  onHandleClick: Function;
  tableInstance: TableInstance<{}>
  tableIndentifier?: string
}

export function GridColumnsDialog ({
  open,
  onHandleClick,
  tableInstance,
  tableIndentifier
}: ColumnsDialogProps) {
  const greyColor = grey[400]
  const handleClose = () => {
    onHandleClick(false)
  }

  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }: any, ref) => {
      const defaultRef = useRef()
      const resolvedRef: any = ref || defaultRef
  
      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return <FormControlLabel control={<Checkbox  ref={resolvedRef} {...rest} />} label="Selecionar todos" />
    }
  )

  const saveAction = () => {
    const columns = tableInstance.allColumns.filter(column => !column.isVisible).map(column => column.id);
    localStorage.setItem(`${tableIndentifier}-hidden-columns`, JSON.stringify(columns));
    tableInstance.setHiddenColumns(columns)
    handleClose()
  }

  const cancelAction = () => {
    const columns = localStorage.getItem(`${tableIndentifier}-hidden-columns`);
    const loadColumns = columns !== null ? JSON.parse(columns) : [];
    tableInstance.setHiddenColumns(loadColumns);
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} >
      <DialogContent>
        <IconButton
          style={{ position: "absolute", top: "15px", right: "10px" }}
          onClick={handleClose}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText className="">
          <IntlEnum name="grid_settings" value="dialog_message" />
        </DialogContentText>
        <FormGroup>
          <IndeterminateCheckbox {...tableInstance.getToggleHideAllColumnsProps()} /> 
          {tableInstance.allColumns.map((column: any) => {
            return (
              <div key={column.id}>
                <FormControlLabel control={<Checkbox {...column.getToggleHiddenProps()} />} label={column.Header ? column.Header : 'Detalhes'} />
              </div>
            )
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}} className="mb-5">
        <Button type="button" onClick={saveAction}>
            <IntlEnum name="grid_settings" value="save_button"/>
        </Button>
        <Button onClick={cancelAction} sx={{ backgroundColor: greyColor }}>
            <IntlEnum name="grid_settings" value="cancel_button"/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}